import { defineStore } from "pinia";

export const useActionStore = defineStore("action", {
  state: () => ({
    name: null,
    target: {
      class: {
        label: null
      },
      instance: {
        id: null,
        label: null
      },
      blacklist: null
    }
  }),
  actions: {
    initiate(actionData) {
      this.name = actionData.name;
      this.target.class.label = actionData.target.class.label;
      if (actionData.target?.instance) {
        this.target.instance.id = actionData.target.instance.id;
        this.target.instance.label = actionData.target.instance.label;
      }
      if (actionData.target?.blacklist) {
        this.target.blacklist = actionData.target.blacklist;
      }
    },
    getMessage(): string {
      try {
        /* Action is often null:
            - when updating profile info
            Action name is equal to "update":
            - when updating a user as a superadmin
            - when updating a machine
         */
        if (this.name === "create") {
          return "Created " + this.target.class.label + ".";
        } else if (this.name === "update") {
          const msg =
            this.target.class.label +
            " " +
            this.target.instance.label +
            " has been updated.";
          return msg;
        }
      } catch {
        console.error("error in getNotificationMessage");
        return "";
      }
      return "";
    },
    destroy() {
      this.name = null;
      this.target.class.label = null;
      this.target.instance.id = null;
      this.target.instance.label = null;
      this.target.blacklist = null;
    }
  }
});
